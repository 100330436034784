import React, { useState, useEffect, useCallback } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ClipLoader from "react-spinners/ClipLoader";
import MediaQuery from "react-responsive";

export const Contact = (placeholders, checkbox1, checkbox2, locale) => {
  let Swal;

  if (typeof window !== undefined) {
    Swal = require("sweetalert2");
  }
  const [marketingConsent, setMarketingConsent] = useState("No");
  const [entries, setEntries] = useState({
    fullname: "",
    email: "",
    organization: "",
    message: "",
    phone: "",
  });
  const [nameErr, setNameErr] = useState();
  const [emailErr, setEmailErr] = useState();
  const [numberErr, setNumberErr] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [imgUpload, setImgUpload] = useState("");
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [country, setCountry] = useState(locale === "ar-AE" ? "ae" : "us");
  const [checkboxStatus, setCheckboxStatus] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  let count = 0;
  const onChangeFile = (e) => {
    var file = e.target.files[0];
    setIsFilePicked(true);
    setImgUpload(file);
  };

  const deleteUploadedFile = () => {
    setIsFilePicked(false);
    setImgUpload("");
  };

  const { executeRecaptcha } = useGoogleReCaptcha();
  const processReCaptcha = useCallback(async () => {
    try {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return null;
      }

      const token = await executeRecaptcha("submitForm");
      return token || null;
    } catch (e) {
      return null;
    }
  }, [executeRecaptcha]);

  const handleLinkedInCapture = useCallback(async () => {
    try {
      window.lintrk("track", { conversion_id: 6744426 });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleGtagCapture = useCallback(async () => {
    try {
      if (window.gtag) {
        const callback = () => {};
        window.gtag("event", "conversion", {
          send_to: `${
            process.env.GATSBY_LANG === "en"
              ? "AW-10865368368"
              : process.env.GATSBY_LANG === "sv"
              ? "G-PP1G19T52Y"
              : ""
          }/1vEjCPmRrNwDELC6gb0o`,
          event_callback: callback,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleFormSubmit = async (e) => {
    if (entries.email === "") {
      count = count + 1;
      setEmailErr("*Enter Your Email");
    }
    if (entries.email !== "") {
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (entries.email.match(mailformat)) {
        if (
          entries.email.includes("gmail") ||
          entries.email.includes("yahoo") ||
          entries.email.includes("hotmail")
        ) {
          count = count + 1;
          setEmailErr("Enter only business Email");
        } else {
          setEmailErr("");
        }
      } else {
        count = count + 1;
        setEmailErr("*Enter Your Valid Email");
      }
    }
    if (entries.phone !== "") {
      setNumberErr("");
    }
    if (entries.fullname !== "") {
      var regName = /^[A-Za-z\s]+$/;
      if (!regName.test(entries.fullname)) {
        count = count + 1;
        setNameErr("*Enter your Valid full name");
      } else {
        setNameErr("");
      }
    }
    if (entries.phone === "" || entries.phone.length < 8) {
      count = count + 1;
      setNumberErr("*Enter Correct Phone Number");
    }
    if (entries.fullname === "") {
      count = count + 1;
      setNameErr("*Enter Your Full Name");
    }

    const token = await processReCaptcha();

    if (!token) {
      return;
    }
    if (count === 0) {
      const source =
        process.env.GATSBY_LANG === "en"
          ? "opsiocloud.com"
          : process.env.GATSBY_LANG === "sv"
          ? "opsio.se"
          : "opsio.in";
      setNumberErr();
      setNameErr();
      setEmailErr();
      var formdata = new FormData();
      formdata.append(
        "data",
        JSON.stringify({
          ...entries,
          ...token,
          ...source,
          ...marketingConsent,
        })
      );
      formdata.append("files.file", imgUpload);
      let requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      try {
        handleLinkedInCapture();
        handleGtagCapture();

        await fetch(
          `${process.env.GATSBY_API_URL}/contactforms`,
          requestOptions
        );
        await axios.post(`${process.env.GATSBY_API_URL}/email-contact`, {
          ...entries,
          source,
        });

        Swal?.fire({
          title: "Thank you",
          text: "We have received your response. Our team will be in touch with you shortly.",
          icon: "success",
          confirmButtonColor: "#0CCB95",
        });
      } catch (e) {
        setIsVisible(false);
      }

      setEntries({
        fullname: "",
        email: "",
        organization: "",
        message: "",
        phone: "",
      });
      setIsFilePicked(false);
    }
    e.preventDefault();
  };

  useEffect(() => {
    axios.get("https://api.db-ip.com/v2/free/self").then((ipData) => {
      if (locale === "ar-AE") {
        setCountry("ae");
      } else {
        if (ipData?.data?.countryCode) {
          setCountry(ipData.data.countryCode.toLowerCase());
        }
      }
    });
  }, [country]);
  return (
    <div className="contact_page">
      <MediaQuery minWidth={1025}>
        <div className="contactForm">
          <div className="row">
            <div className="contact_from">
              <div className="block">
                <input
                  type="text"
                  className="contactFormInput"
                  value={entries.fullname}
                  placeholder={placeholders.placeholders.name}
                  onChange={(e) =>
                    setEntries({ ...entries, fullname: e.target.value })
                  }
                />
                <br />
                <p className="input-error">{nameErr}</p>
              </div>

              <div
                className="block"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="contact-input">
                  <input
                    type="email"
                    className="contactFormInput mx-2"
                    value={entries.email}
                    placeholder={
                      placeholders.placeholders &&
                      placeholders.placeholders.email
                    }
                    onChange={(e) =>
                      setEntries({ ...entries, email: e.target.value })
                    }
                  />
                  <p className="input-error">{emailErr}</p>
                </div>
                <div className="number-input ms-1 me-0">
                  <PhoneInput
                    country={country}
                    value={entries.phone}
                    onChange={(e) => setEntries({ ...entries, phone: e })}
                    containerClass="intTel"
                    inputClass="intTelInput"
                  />
                  <p className="input-error">{numberErr}</p>
                </div>
              </div>

              <div className="block">
                <input
                  type="text"
                  className="contactFormInput "
                  value={entries.organization}
                  placeholder={
                    placeholders.placeholders &&
                    placeholders.placeholders.organization
                  }
                  onChange={(e) =>
                    setEntries({
                      ...entries,
                      organization: e.target.value,
                    })
                  }
                />
              </div>

              <div className="block">
                <textarea
                  className="contactFormText"
                  value={entries.message}
                  placeholder={
                    placeholders.placeholders &&
                    placeholders.placeholders.message
                  }
                  onChange={(e) =>
                    setEntries({ ...entries, message: e.target.value })
                  }
                />
              </div>

              <div className="  my-3">
                <div className="desktopViewCheckbox">
                  <input
                    type="checkbox"
                    onChange={() => setCheckboxStatus(!checkboxStatus)}
                    checked={checkboxStatus}
                    id="policy"
                    className="mt-2 d-flex align-self-start"
                  />
                  <label
                    htmlFor="#policy"
                    className="text-start"
                    dangerouslySetInnerHTML={{
                      __html: placeholders.checkbox1,
                    }}
                  />
                </div>
              </div>

              <button
                disabled={!checkboxStatus}
                className={`contactFormBtn ${
                  !checkboxStatus ? "disabled" : ""
                }`}
                onClick={handleFormSubmit}
              >
                {placeholders.placeholders.btn}
              </button>
            </div>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={1024}>
        <div className="contactForm containerwrap">
          <div className="row">
            <div className="col-xl-4 col-md-12 col-12">
              <div className="inside_contact">
                <div className="contact_from info_contact">
                  <div className="block">
                    <input
                      type="text"
                      value={entries.fullname}
                      className="contactFormInput"
                      placeholder={placeholders.placeholders.name}
                      onChange={(e) =>
                        setEntries({ ...entries, fullname: e.target.value })
                      }
                      required
                    />
                    <p className="input-error">{nameErr}</p>
                  </div>
                  <div
                    className="block"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <input
                      type="email"
                      value={entries.email}
                      className="contactFormInput"
                      placeholder={placeholders.placeholders.email}
                      onChange={(e) =>
                        setEntries({ ...entries, email: e.target.value })
                      }
                      required
                    />
                    <p className="input-error">{emailErr}</p>
                  </div>
                  <div className="block">
                    <PhoneInput
                      country={"us"}
                      value={entries.phone}
                      onChange={(e) => setEntries({ ...entries, phone: e })}
                      containerClass="intTel"
                      inputClass="intTelInput"
                      required
                    />
                    <p className="input-error">{numberErr}</p>
                  </div>
                  <div className="block">
                    <input
                      type="text"
                      value={entries.organization}
                      className="contactFormInput"
                      placeholder={placeholders.placeholders.organization}
                      onChange={(e) =>
                        setEntries({ ...entries, organization: e.target.value })
                      }
                    />
                  </div>

                  <div className="block">
                    <textarea
                      className="contactFormText"
                      value={entries.message}
                      placeholder={placeholders.placeholders.message}
                      onChange={(e) =>
                        setEntries({ ...entries, message: e.target.value })
                      }
                    />
                  </div>

                  <div className="mobileViewCheckbox">
                    <input
                      type="checkbox"
                      checked={checkboxStatus}
                      className="d-flex align-self-start mt-2"
                      onChange={() => setCheckboxStatus(!checkboxStatus)}
                      id="policy"
                    />
                    <label
                      htmlFor="#policy"
                      className="text-start"
                      dangerouslySetInnerHTML={{
                        __html: placeholders.checkbox1,
                      }}
                    />
                  </div>
                 
                  <div className="d-flex justify-content-center mt-3">
                    {submitLoading ? (
                      <button className="contactFormBtn disabled">
                        <ClipLoader
                          color={"#fff"}
                          loading={true}
                          size={35}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </button>
                    ) : (
                      <button
                        disabled={!checkboxStatus}
                        className={`contactFormBtn  ${
                          !checkboxStatus ? "disabled" : ""
                        }`}
                        onClick={handleFormSubmit}
                      >
                        {placeholders.placeholders.btn}
                      </button>
                    )}
                  </div>

                  {isVisible && (
                    <div
                      className="submit_contact_message"
                      style={{ color: "#0ED39B" }}
                    >
                      Thank you, our team will be in touch with you shortly.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>
    </div>
  );
};
